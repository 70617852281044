import React from "react";
import PropTypes from "prop-types";
import NavBar from "./NavBar";
import Hero from "./Hero";
import { Container, Row } from "react-bootstrap";
import Footer from "./Footer";

const Home = (props) => {
  return (
    <div>
    
      <Container className="App">
        <Row>
          <Hero/>
        </Row>
        <Row>
            <Footer/>
        </Row>
      </Container>
    </div>
  );
};
export default Home;
