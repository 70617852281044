import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';

const Math = () => {
    const topics = ['Quadratic Equations'];
    const conceptsAndSheets = ['Concept 1'];
    const youtubeVideos = ['Video 1'];
    const sheetLinks=["https://docs.google.com/document/d/1ONzNiSfbk9DPGjAeIRRuzK-gGc6dO-iPyiiCeF8TyHc/edit"]
    const videoLinks=["https://www.youtube.com/watch?v=1Pva-Iv43Nc&list=PLFACC72B70062EB2D"]

    return (
        <Container>
            <Row className="mb-4">
                <Col>
                    <h1 style={{ color: 'green', fontWeight: 'bold' }}>Math Resources</h1>
                    <h4 style={{ color: 'green' }}>This page will continue to update as the school year progresses for anyone to use.</h4>
                </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Topic/Concept</th>
                        <th>Practice Sheets</th>
                        <th>Helpful Youtube Videos</th>
                    </tr>
                </thead>
                <tbody>
                    {topics.map((topic, index) => (
                        <tr key={index}>
                            <td>{topic}</td>
                            <td><a href={sheetLinks[index]} target="_blank" rel="noopener noreferrer">View Practice Sheet</a></td>
                            <td><a href={videoLinks[index]} target="_blank" rel="noopener noreferrer">Watch Video</a></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );

};

export default Math;
