import logo from "./logo.svg";
import "./App.css";
import { Button, Container, Navbar } from "react-bootstrap";
import Home from "./Components/Home";
import Math from './Components/Math'
import "bootstrap/dist/css/bootstrap.min.css";
import CrutcherLogo from "../src/Assets/CrutcherTutoringLogo.png"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  RouterProvider,
} from "react-router-dom";
import Resources from "./Components/Resources/Resources";
import AboutMe from "./Components/AboutMe";

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path="/Resources" element={<Resources />}/>
        <Route path="/Math" element={<Math/>}/>
        <Route path="/aboutme" element={<AboutMe/>}/>
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router}/>
    </div>
  );
}

const Root = () => {
  return (
    <div>
      <Navbar style={{ backgroundColor: "#D9D9D9" }}>
        <Container>
        <Navbar.Brand>
        <Link to="/" style={{ color: "#0C9212", marginRight: "15px", textDecoration: "none" }}>
    <img src={CrutcherLogo} width={"50vw"}/>
</Link>

</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
          <Link
              to={"/aboutme"}
              style={{ color: "#0C9212", marginRight: "15px" }}
            >
              About Me
            </Link>
            <Link
              to={"/resources"}
              style={{ color: "#0C9212", marginRight: "15px" }}
            >
              Resources
            </Link>
            <Link to={"https://docs.google.com/forms/d/e/1FAIpQLSdAZRl2jemQvTEBdrkFIdsmx-Rbq7__foMfmks5h3VifuLAKQ/viewform?usp=sf_link"} style={{ color: "#0C9212" }}>Book a Session</Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div>
        <Outlet />
      </div>
    </div>
  );
};

export default App;
