import React from 'react';
import { Container, Row } from 'react-bootstrap';

function Footer() {
    return (
        <Container fluid className=" py-3">
            <Row className="justify-content-center text-center">
                <span style={{ color: '#0C9212' }}>
                    crutchertutoring@gmail.com • Crutcher Tutoring
                </span>
            </Row>
        </Container>
    );
}

export default Footer;
