import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import NavBar from '../NavBar'
import { Link } from 'react-router-dom';



const Resources = props => {
    return (
<Container style={{height:'60vh', padding: '2rem'}}>
    <Row className='justify-content-center align-items-center'>
    <Modal.Dialog>
        <Modal.Header  className='justify-content-center'>
          <Modal.Title style={{fontSize:'8vw'}}>Study Resources! </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>As the school year goes on I will update this resource for anyone to use. 
          </p>
          <p> Free study sheets, videos, and youtube videos </p> 
        </Modal.Body>

      </Modal.Dialog>
    </Row>
    <Row className="mb-4">
        <Col className='d-flex align-items-center justify-content-center'>
            <h3 style={{color: 'green', display:'flex'}}>Select a topic to Study!</h3>
        </Col>
    </Row>
    <Row style={{height:'100%'}} className="align-items-center">
        <Col className='d-flex align-items-center justify-content-center'>
            <Link to="/math">
                <Button style={{height:'15vh',width:'25vw', fontSize:'5vw' }}  variant="success">Math</Button>
            </Link>
        </Col>
        <Col className='d-flex align-items-center justify-content-center'>
                <Button style={{height:'15vh',width:'25vw',fontSize:'5vw' }} disabled={true} variant="success">Physics</Button>
        </Col>
    </Row>
</Container>


    );
};

Resources.propTypes = {
    
};

export default Resources;