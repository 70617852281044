import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

function AboutMe() {
    return (
        <Container>
            <Row className="mt-5">
                <Col md={4}>
                    {/* Place an image of yourself here */}
                    <Image src="https://i.imgur.com/K9FgNub.png" roundedCircle width="100%" />
                </Col>
                <Col md={8} className="text-left">
                    <h2>About Me</h2>
                    <p style={{textAlign:'left'}}>
                        Hello, I'm Dorian Crutcher. I pursued Mechanical Engineering and Robotics at UC Davis, where I tackled the unique challenges of an engineering curriculum. There, I honed my skills as a technical workshop coordinator, guiding participants from diverse backgrounds in rapid prototyping—from web development to 3D printing and microcontroller programming.
                    </p>
                    <p style={{textAlign:'left'}}>
                        Post-graduation, my journey took me through roles in mechanical engineering and software development, further solidifying my technical expertise. Now, I'm on an entrepreneurial path, laying the foundation for my software engineering venture.
                    </p>
                    <p style={{textAlign:'left'}}>
                        As a tutor, my mission is to illuminate the wonders of math and physics and bestow effective study strategies. My philosophy is rooted in the “teach to teach” approach. I believe that when one explains a concept, they gain a deeper grasp of the subject than the learner. My sessions often connect academic concepts to real-world applications, answering the frequent student question: "Why am I learning this?"
                    </p>
                    <p style={{textAlign:'left'}}>
                        I'm eager to collaborate with you. Moreover, I'll be sharing resources and notes on this platform, enabling self-driven learning.
                    </p>
                    <p style={{textAlign:'left'}}>
                        Warm regards,<br />
                        Dorian Crutcher
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;