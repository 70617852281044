import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import "../CSS/carouselStyle.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

function HeroSection() {
  return (
    <Container className="hero-container" style={{ marginTop: "3vh" }}>
      <Row>
        {/* Image Column */}
        <Col md={6}>
          <Image
            style={{ width: "100%" }}
            src="https://i.imgur.com/AJ0X5oz.png"
            alt="Dorian Crutcher's Image"
          />
        </Col>

        {/* Text and Button Column */}
        <Col md={6} className="d-flex flex-column">
          <Row
            className="flex-grow-1 justify-content-center align-items-center"
            style={{ height: "75%" }}
          >
            <Carousel
              autoPlay={true}
              showIndicators={false}
              showArrows={false}
              showStatus={false}
              infiniteLoop={true}
              interval={7000}
            >
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <p>
                  "My teaching approach for math and physics begins by
                  illustrating real-world applications of the concepts. This
                  makes them relatable. We then delve into the details and
                  practice problems to solidify understanding."
                </p>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <p>
                 " I teach students how to teach others. In my experience,
                  there's no better way to understand a subject than teaching it
                  yourself."
                </p>
              </div>
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "100%" }}
              >
                <p>
                 " I have 7+ years of real-world engineering experience and
                  workshop teaching experience. This will be valuable in helping
                  your student clearly understand these technical concepts."
                </p>
              </div>
            </Carousel>
          </Row>

          <Row
            className="justify-content-center align-items-center"
            style={{ height: "25%" }}
          >
            <Button
              style={{
                backgroundColor: "#0C9212",
                fontWeight: "bold",
              }}
              href="https://docs.google.com/forms/d/e/1FAIpQLSdAZRl2jemQvTEBdrkFIdsmx-Rbq7__foMfmks5h3VifuLAKQ/viewform?usp=sf_link"
            >
              Book a Session
            </Button>
            <p>Please remember to select your session time <a href="https://calendly.com/crutchertutoring">here</a> after filling out form</p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default HeroSection;
